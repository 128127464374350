<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>

                <b-form @submit.prevent="search()" >
                  <div class="filter-transaction">
                  <div class="row mb-6">
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Số điện thoại:</label>
                      <input
                          type="text"
                          placeholder="Nhập số điện thoại sinh viên"
                          class="form-control datatable-input"
                          v-model="paramFilter.phone"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Tên sinh viên:</label>
                      <input
                          type="text"
                          placeholder="Nhập tên sinh viên"
                          class="form-control datatable-input"
                          v-model="paramFilter.name"
                      />
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Trạng thái nhận thưởng</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.status_bonus"
                      >
                        <option value="">Tất cả</option>
                        <option value="0">Đang thực hiện</option>
                        <option value="1">Hoàn thành</option>
                        <option value="2">Từ bỏ</option>
                      </select>
                    </div>

                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Trạng thái gửi mail</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.is_send_email"
                      >
                        <option value="">Tất cả</option>
                        <option value="0">Chưa gửi otp</option>
                        <option value="1">Đã gửi otp</option>
                        <option value="2">Có lỗi xảy ra khi gửi otp</option>
                      </select>
                    </div>
                  </div>

                  <div class="row mb-6">
                    <b-form-group
                        id="input-group-4"
                        label="Từ ngày:"
                        label-for="dp1"
                        class="col-lg-4"
                    >
                      <date-picker
                          id="dp1"
                          name="dp1"
                          format="YYYY-MM-DD "
                          value-type="format"
                          v-model="paramFilter.from_date"
                          type="date"
                          placeholder="Chọn thời gian"
                      ></date-picker>
                    </b-form-group>
                    <b-form-group
                        id="input-group-4"
                        label="Đến ngày:"
                        label-for="dp1"
                        class="col-lg-4"
                    >
                      <date-picker
                          id="dp1"
                          name="dp1"
                          format="YYYY-MM-DD "
                          value-type="format"
                          v-model="paramFilter.to_date"
                          type="date"
                          placeholder="Chọn thời gian"
                      ></date-picker>
                    </b-form-group>
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                          <span>
                            <i class="la la-search"></i>
                              <span>Tìm kiếm</span>
                            </span>
                      </button>
                    </div>
                  </div>

                </div>
                </b-form>
              </template>

              <b-table
                table-class="datatable-table"
                hover
                tbody-tr-class="datatable-row"
                thead-tr-class="datatable-row"
                thead-class="datatable-head"
                details-td-class="datatable-cell"
                :items="items"
                :fields="fields"
              >
                <template #cell(stt)="data">
                  <div >
                    {{ data.index + 1}}
                  </div>
                </template>

                <template #cell(info)="data">
                  <div>
                    <span>User ID:</span> {{data.item.uuid }}
                  </div>
                  <div>
                    <span>Họ tên:</span> {{data.item.name }}
                  </div>
                  <div>
                    <span>SĐT:</span> {{data.item.phone }}
                  </div>
                  <div>
                    <span>Email:</span> {{ data.item.mission.value }}
                  </div>
                </template>
                <template #cell(refer_info)="data">
                  <div v-if="data.item.refer" >
                    <div>
                      <span>User ID:</span> {{data.item.refer.user_info.uuid }}
                    </div>
                    <div v-if="data.item.refer.user_info.name">
                      <span>Họ tên:</span> {{data.item.refer.user_info.name }}
                    </div>
                    <div>
                      <span>SĐT:</span> {{data.item.refer.user_info.phone }}
                    </div>
                  </div>
                  <div v-else>
                      -
                  </div>
                </template>

                <template #cell(status)="data">
                   <div v-if="data.item.refer" style="margin-bottom: 5px;">
                     <span :class="getClassStatus('refer', data.item.refer.status)"> Refer: {{ data.item.refer.label }} </span>
                   </div>
                  <div v-if="data.item.mission" style="margin-bottom: 5px;">
                    <span :class="getClassStatus('send-email', data.item.mission.is_send_email)"> Gửi mail: {{ data.item.mission.label_send_email }} </span>
                  </div>
                  <div v-if="data.item" style="margin-bottom: 5px;">
                    <span :class="getClassStatus('challenge', data.item.status.value)"> Thử thách: {{ data.item.status.label }} </span>
                  </div>
                  <div v-if="data.item.status_bonus" style="margin-bottom: 5px;">
                    <span :class="getClassStatus('gift', data.item.status_bonus.status_bonus)"> Quà tặng: {{ data.item.status_bonus.status_bonus_label }} </span>
                  </div>
                </template>
                <template #cell(created_at)="data">
                  <div>
                    {{formatDate(data.item.created_at)}}
                  </div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex">
                    <b-button v-if="data.item.can_actions.can_approve_refer" style="margin-right: 10px;" pill variant="warning" size="sm" >
                      Phê duyệt
                    </b-button>
                      <b-button v-if="data.item.status.value != 2" style="margin-right: 10px;" pill variant="info" size="sm" @click="resendOtp(data.item)">
                        Gửi lại Otp
                      </b-button>
                    <b-button v-if="data.item.can_actions.can_retry_gift" style="margin-right: 10px;" pill variant="danger" size="sm" @click="retryGift()">
                      Phát lại quà
                    </b-button>
                    <b-button variant="info" size="sm" @click="goToUniversity(data.item)">
                      Xem thông tin trường
                    </b-button>
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :number-of-pages="paginate.totalPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.limit"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  name: "ReportCampaignStudent.vue",
  mixins: [Common],
  components:{
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "stt",
          label: "STT",
        },
        {
          key: "info",
          label: "Thông SV tham gia",
        },
        {
          key: "refer_info",
          label: "Người giới thiệu",
        },
        {
          key: "status",
          label: "Trạng thái",
        },
        {
          key: "created_at",
          label: "Ngày tham gia",
        },
        {
          key: "action",
          label: "Hành động",
        },
      ],
      items: [],
      currentPage: 1,
      paramFilter:{
        phone:'',
        name:'',
        is_send_email:'',
        status_bonus:'',
        from_date:'',
        to_date:'',
      },
      arrayFilter:[],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Báo cáo chiến dịch sinh viên", route: "/campaign/report-student" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    goToUniversity (item) {
      window.open(`/campaign/university/edit/${item.university_id}`);
    },
    getClassStatus(type, status) {
      if (type === 'refer') {
          if (status === 0) {
            return "label label-lg label-inline label-light-warning";
          } else {
            return "label label-lg label-inline label-light-success";
          }
      } else if (type === 'send-email') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      } else if (type === 'challenge') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-primary";
        } else if (status === 2) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      } else if (type === 'gift') {
        if (status === 0) {
          return "label label-lg label-inline label-light-warning";
        } else if (status === 1) {
          return "label label-lg label-inline label-light-success";
        } else {
          return "label label-lg label-inline label-light-danger";
        }
      }
    },
    formatDate(value){
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await CampaignRepository.getListReportStudent(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;
        if (body.data) {
          let list =  body.data;
          this.items = list.data;
          this.arrayFilter = body.data.array_filter;
          this.paginate.total = list.meta.total;
          this.paginate.totalPage = list.meta.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    retryGift () {
      alert('Tính năng đang phát triển...');
    },
    async resendOtp(item){
      let params = {
        userChallengeId: item.id,
        email: item.mission.value,
        userId: item.uuid
      }
      let response = await CampaignRepository.resendOtp(params);
      if (!response) {
        this.$notify({
              group: "foo",
              type: "error",
              title: 'Có lỗi xảy ra khi gửi lại mail',
            });
      }else{
        this.$notify({
              group: "foo",
              type: "success",
              title: 'Gửi lại mail thành công',
            });
      }
      this.paramFilter.phone = item.phone;
      return this.getList();
    },

    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getClients();
    this.getList();
  }
};
</script>

<style >
.mx-datepicker{
  width: 100%;
}

</style>
